import React from 'react';
import { Box, HeadingDisplay, Text } from '@hausgold/designsystem';
import Layout from '../formwizard/src/components/variants/Default/Layout';

const PageNotFound = () => (
  <Layout>
    <Box textAlign="center">
      <HeadingDisplay>Seite nicht gefunden</HeadingDisplay>
      <Text>
        Wir konnten diese Seite leider nicht finden. Bitte überprüfen Sie, ob
        der Link korrekt ist.
      </Text>
    </Box>
  </Layout>
);

export default PageNotFound;
